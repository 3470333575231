<template>
  <transition name="modal">
    <div
      class="modal-mask modal-mask-scroll-sp modal-center"
      v-if="dialogVisible"
    >
      <div class="modal-wrapper">
        <div class="saved-dialog" @click.stop>
          <button class="close-button-top" @click.prevent="hideDialog">
            <i class="material-icons">close</i>
          </button>
          <div>
            <div class="text-center saved-dialog-title">
              <p>検索条件を保存する</p>
            </div>
            <div class="saved-dialog-body">
              <ul class="saved-dialog-list">
                <li>
                  <h4 class="heading4">保存する条件</h4>
                  <p class="saved-keyword">
                    {{ currentCriterion.keyword }}
                  </p>
                </li>
                <li>
                  <h4 class="heading4">新着Q&amp;Aメール通知設定</h4>
                  <p class="saved-dialog-annotation">
                    保存した条件にマッチする新着Q&amp;Aが投稿された際、メールでお知らせします（１日１回）
                  </p>
                  <div class="form-switch material-list-item__button">
                    <input
                      class="form-switch__native-control js-notify-switch"
                      role="switch"
                      type="checkbox"
                      :checked="notify"
                      @click="notify = !notify"
                    />
                    <div class="form-switch__background">
                      <div class="form-switch__knob"></div>
                    </div>
                  </div>
                </li>
              </ul>

              <button
                type="button"
                class="button button--primary button--full button--lg js-save"
                @click="saveCriteria"
              >
                この設定で保存する
              </button>
            </div>

            <div class="text-center close-button-bottom">
              <a
                class="link-underline js-cancel"
                href="#"
                @click.prevent="hideDialog"
                >閉じる</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { captureEvent } from '../../../../common/utils';
import { showFadeDialog } from '../../../../common/functions';

export default {
  data() {
    return {
      notify: true
    };
  },

  watch: {
    dialogVisible(newVisible) {
      // IEが対応していないらしいので classList.toggle('fixed', newVisible) にできない
      document.body.classList[newVisible ? 'add' : 'remove']('fixed');
    }
  },

  computed: {
    ...mapGetters(['currentCriterion', 'dialogVisible'])
  },

  methods: {
    ...mapActions(['showDialog', 'hideDialog', 'saveCurrentCriterion']),

    async saveCriteria() {
      captureEvent({
        category: '検索結果一覧',
        action: 'ButtonClick',
        label: 'search-criteria-save'
      });

      try {
        await this.saveCurrentCriterion(this.notify);
        showFadeDialog('検索条件を保存しました');
        this.hideDialog();
      } catch (error) {
        if (error.code && error.message) {
          // Askdoctorsのアプリケーションエラーかどうか
          // このエラーが出るような状態はJS側でチェックしてそもそもボタンを押せないようにしているが、タイミングの問題などで発生することはある。
          // エラー表示のUIを作り込むほどではないが表示しないのも困るのでalertを使う。
          alert(error.message);
        } else {
          throw error;
        }
      }
    }
  }
};
</script>
